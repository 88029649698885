import { request } from "../../utils/request";
import { dataActions } from "../slices/subscribersSlice";
import { toast } from "react-toastify";
import { setIdCookie } from "../../utils/cockies";

//Admin:
//Login Admin:
export const AdminLogin = (_state, setState, userData) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      const { data } = await request.post(`/auth/login_admin`, userData);
      setIdCookie(data.user);
      dispatch(dataActions.login(data.user));
      setState(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Get All Admins:
export const GetAdmins = (page, perPage) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      const { data } = await request.get(
        `/users/profile/admin?page=${page}&perPage=${perPage}`
      );
      dispatch(dataActions.getAdmins(data.admins));
      return data;
    } catch (error) {
      console.log(error);
      return error;
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Add Admins:
export const AddAdmins = (infoAdmin) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.post("/auth/register_admin", infoAdmin);
      toast.success("تمت اضافة أدمن");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Delete Admins
export const DeleteAdmins = (setOpen, IdAdmin, token) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.delete(`/users/profile/${IdAdmin}`, {
        headers: { Authorization: `Bearar ${token}` },
      });
      dispatch(dataActions.deleteAdmin(IdAdmin));
      toast.success("تم حذف الأدمن");
      setOpen(false);
    } catch (error) {
      console.log(error.message);
      toast.error(error.response.data.message);
      setOpen(false);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Users:
//Get All Users:
export const GetUsers = (page, perPage, subjectId) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      let apiEndpoint;
      if (subjectId === "كل المواد" || !subjectId) {
        apiEndpoint = `/users/profile?page=${page}&perPage=${perPage}`;
      } else {
        apiEndpoint = `/users/profile?subjectId=${subjectId}&page=${page}&perPage=${perPage}`;
        console.log(subjectId);
      }
      const { data } = await request.get(apiEndpoint);
      dispatch(dataActions.getUsers(data.users));
      dispatch(dataActions.getCountUsers(data.totalCount));
      return data;
    } catch (error) {
      console.log(error);
      return error;
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Add Users:
export const AddUsers = (infoUser) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.post("/auth/register", infoUser);
      toast.success(`تمت اضافة مستخدم`);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Delete Users:
export const DeleteUsers = (setOpen, IdUser, token) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.delete(`/users/profile/${IdUser}`, {
        headers: { Authorization: `Bearar ${token}` },
      });
      dispatch(dataActions.deleteUsers(IdUser));

      toast.success(`تم حذف مستخدم`);
      setOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setOpen(false);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Code:
//Get All Code:
export const GetCodes = (page, perPage) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      const { data } = await request.get(
        `/codes?page=${page}&perPage=${perPage}`
      );
      dispatch(dataActions.getCodes(data.codes));
      return data;
    } catch (error) {
      console.log(error);
      return error;
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Add Code:
export const AddCode = (setState, infoCode) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.post(`/codes/generateCode`, infoCode);
      setState(true);
      toast.success("تمت اضافة كود");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Delete Code:
export const DeleteCode = (setOpen, IdCode) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.delete(`/codes/${IdCode}`);
      dispatch(dataActions.deleteCode(IdCode));
      toast.success(`تم حذف الكود`);
      setOpen(false);
      // handle the response as needed
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
      setOpen(false);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Packages:
//Get Packages:
export const GetPackages = (page, perPage) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      const { data } = await request.get(
        `/codes/packages?page=${page}&perPage=${perPage}`
      );
      dispatch(dataActions.getPackages(data.codes));
      return data;
    } catch (error) {
      console.log(error);
      return error;
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Add Packages:
export const AddPackage = (setState, infoPackage) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.post(`/codes/generateCode`, infoPackage);
      setState(true);
      toast.success("تمت اضافة الباقة");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Delete Package:
export const DeletePackage = (setOpen, IdPackage) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.delete(`/codes/${IdPackage}`);
      dispatch(dataActions.deletePackage(IdPackage));
      toast.success(`تم حذف الباقة`);
      setOpen(false);
      // handle the response as needed
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
      setOpen(false);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Download QR:
export const DownlaodQR = (setDownload, codeNumber) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      const response = await request.post(
        "/codes/download-image",
        {
          codeNumber,
        },
        { responseType: "blob" } // Set the response type to "blob" to handle binary data
      );

      // Create a download link for the image
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "qrcode.png");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the download URL
      window.URL.revokeObjectURL(downloadUrl);
      setDownload(false);
      toast.success("تم تحميل الكود");
    } catch (error) {
      toast.error(error.response.data.message);
      setDownload(false);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Get All Centers:
export const GetCenter = (page, perPage, country) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      const apiEndpoint =
        country === "كل المحافظات" || country === ""
          ? `/centers?page=${page}&perPage=${perPage}`
          : `/centers?governorate=${country}&page=${page}&perPage=${perPage}`;
      const { data } = await request.get(apiEndpoint);
      dispatch(dataActions.getCenters(data.center));
      return data;
    } catch (error) {
      console.log(error);
      return error;
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Post Center:
export const AddCenter = (setState, infoCenter) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.post("/centers", infoCenter);
      toast.success(`تمت اضافة مركز`);
      setState(true);
      // handle the response as needed
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Edit Center:
export const EditCenter = (
  state,
  setState,
  error,
  setErorr,
  IdCenter,
  infoCenterEdit
) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.put(`/centers/${IdCenter}`, infoCenterEdit);
      toast.success(`تمت تعديل مركز`);
      setState(true);
      setErorr(null);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setErorr(error);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Delete Center:
export const DeleteCenter = (setOpen, IdCenter) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.delete(`/centers/${IdCenter}`);
      dispatch(dataActions.deleteCenters(IdCenter));
      setOpen(false);
      toast.success(`تم حذف مركز`);
    } catch (error) {
      console.error(error);
      setOpen(false);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Subject:
//Get All Subjects:
export const GetSubjects = (sideId, page, perPage) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      const { data } = await request.get(
        `/subjects?sideId=${sideId}&page=${page}&perPage=${perPage}`
      );
      dispatch(dataActions.getSubjects(data.subjects));
      return data;
    } catch (error) {
      console.log(error);
      return error;
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Get All Subject Without Side:
export const GetSubject = (page, perPage) => {
  return async (dispatch) => {
    try {
      const { data } = await request.get(
        `/subjects?page=${page}&perPage=${perPage}`
      );
      dispatch(dataActions.getSubjects(data.subjects));
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};

//Add Subjects:
export const AddSubject = (setState, infoSubject) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.post("/subjects", infoSubject);
      setState(true);
      toast.success(`تمت اضافة مادة`);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Edit Subjects
export const EditSubject = (setState, IdSubject, infoSubjectEdit) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.put(`/subjects/${IdSubject}`, infoSubjectEdit);
      toast.success("تم تعديل المادة");
      setState(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Delete Subject:
export const DeleteSubject = (IdSubject) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.delete(`/subjects/${IdSubject}`);
      dispatch(dataActions.deleteSubject(IdSubject));
      toast.success(`تم حذف المادة`);
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Question:
//Get Questions:
export const GetQuestions = (page, perPage, subjectId) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      let apiEndpoint;
      if (subjectId === "كل المواد" || !subjectId) {
        apiEndpoint = `/questions?page=${page}&perPage=${perPage}`;
      } else {
        apiEndpoint = `/questions?subjectId=${subjectId}&page=${page}&perPage=${perPage}`;
      }
      console.log(apiEndpoint);
      const { data } = await request.get(apiEndpoint);
      dispatch(dataActions.getQuestion(data.questions));
      return data;
    } catch (error) {
      console.log(error);
      return error;
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Add Questions:
export const AddQuestions = (infoQuestion) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      const { data } = await request.post("/questions", infoQuestion);
      toast.success("تم اضافة سؤال");
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Apply EXCEL;
export const ExcelFile = (
  Lesson,
  Unit,
  Subject,
  infoQuestion,
  onUploadProgress
) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete()); // Starts loading or delete state (based on your app logic)

    try {
      const { data } = await request.post(
        `/questions/upload-questions?lesson=${Lesson}&unit=${Unit}&subject=${Subject}`,
        infoQuestion,
        {
          onUploadProgress: (progressEvent) => {
            if (onUploadProgress) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              onUploadProgress(percentCompleted); // Update progress in the component
            }
          },
        }
      );
      toast.success("تم رفع الملف");
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Atchment Questions:
export const AddAttachmentQuestion = (infoAtachment) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    console.log(infoAtachment);
    try {
      const { data } = await request.post(
        "/questions/update-images",
        infoAtachment
      );
      toast.success("تم اضافة ملحقات السؤال");
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Edit Atchment Questions:
export const DeleteAttachmentQuestion = (id, infoAtachment) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    console.log(infoAtachment.propertyName);
    try {
      const { data } = await request.delete(
        `/questions/delete-question-image/${id}`,
        {
          data: { propertyName: infoAtachment.propertyName },
        }
      );
      toast.success("تم حذف ملحقات السؤال");
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Edit Questions:
export const EditQuestions = (setState, IdQuestion, infoQuestion) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.put(`/questions/${IdQuestion}`, infoQuestion);
      toast.success("تم تعديل سؤال");
      setState(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Delelte Questions:
export const DeleteQuestions = (setOpen, IdQuestion) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.delete(`/questions/${IdQuestion}`);
      dispatch(dataActions.deleteQuestions(IdQuestion));
      toast.success("تم حذف السؤال");
      setOpen(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Units:
//Get Units:
export const GetUnits = (token, page, perpage, value) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      let apiEndpoint;
      if (value === "كل المواد" || !value) {
        apiEndpoint = `/units/admins?page=${page}&perPage=${perpage}`;
      } else {
        apiEndpoint = `/units/admins?subjectId=${value}&page=${page}&perPage=${perpage}`;
      }
      const { data } = await request.get(apiEndpoint, {
        headers: { Authorization: `Bearar ${token}` },
      });
      dispatch(dataActions.getUnits(data.units));
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      return error;
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Add Units:
export const AddUnits = (setState, infoUnit) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.post("/units", infoUnit);
      toast.success("تمت اضافة وحدة");
      setState(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Edit Units:
export const EditUnits = (setState, IdUnits, infoUnit) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.put(`/units/${IdUnits}`, infoUnit);
      toast.success("تمت تعديل الوحدة");
      setState(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Delete Units:
export const DeleteUnits = (setOpen, IdUnits) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.delete(`/units/${IdUnits}`);
      dispatch(dataActions.deleteUnits(IdUnits));
      setOpen(false);
      toast.success(`تم حذف الوحدة`);
    } catch (error) {
      console.error(error);
      setOpen(false);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Lesson:
//Get Lessons:
export const GetLessons = (token, page, perpage, UnitID) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      let apiEndpoint;
      if (UnitID === "كل الوحدات" || !UnitID) {
        apiEndpoint = `/lessons?page=${page}&perPage=${perpage}`;
      } else {
        apiEndpoint = `/lessons?unitId=${UnitID}&page=${page}&perPage=${perpage}`;
        console.log(UnitID);
      }

      const { data } = await request.get(apiEndpoint, {
        headers: { Authorization: `Bearar ${token}` },
      });
      dispatch(dataActions.getLessons(data.lessons));
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      return error;
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Add Lessons:
export const AddLessons = (setState, infoLesson) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.post("/lessons", infoLesson);
      toast.success("تمت اضافة الدرس");
      setState(true);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Edit Lessons:
export const EditLessons = (setState, IdLesson, infoLesson) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.put(`/lessons/${IdLesson}`, infoLesson);
      toast.success("تمت تعديل الدرس");
      setState(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Delete Lessons:
export const DeleteLessons = (setOpen, IdLesson) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.delete(`/lessons/${IdLesson}`);
      dispatch(dataActions.deleteLessons(IdLesson));
      setOpen(false);
      toast.success("تمت حذف الدرس");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Notifcation:
//Get Message:
export const GetMessage = (page, perPage) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      const { data } = await request.get(
        `/message?page=${page}&perPage=${perPage}`
      );
      dispatch(dataActions.getMessage(data.message));
      return data;
    } catch (error) {
      console.log(error);
      return error;
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

//Delete Message:
export const DeleteMessage = (IdMessage) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.delete(`/message/${IdMessage}`);
      dispatch(dataActions.deleteMessage(IdMessage));
      // setOpen(false);
      toast.success("تمت حذف الرسالة");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

//Notifcation:
//Get Notifcation:
export const GetNotifcation = (page, perPage) => {
  return async (dispatch) => {
    dispatch(dataActions.setLoading());
    try {
      const { data } = await request.get(
        `/notifications?page=${page}&perPage=${perPage}`
      );
      dispatch(dataActions.getNotification(data.notifications));
      return data;
    } catch (error) {
      console.log(error);
      return error;
    } finally {
      dispatch(dataActions.setLoading());
    }
  };
};

export const PostNotifcation = (infoNotification, subjectId) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      let apiEndpoint;
      if (subjectId) {
        apiEndpoint = `/notifications?subjectId=${subjectId}`;
      } else {
        apiEndpoint = "/notifications";
      }
      await request.post(apiEndpoint, infoNotification);
      toast.success("تم ارسال الإشعار");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};

export const DeleteNotification = (IdNotification) => {
  return async (dispatch) => {
    dispatch(dataActions.setDelete());
    try {
      await request.delete(`/notifications/${IdNotification}`);
      dispatch(dataActions.deleteNotification(IdNotification));
      toast.success("تمت حذف الاشعار");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(dataActions.setDelete());
    }
  };
};
//Sides:
//Get All Sides:
export const GetSides = () => {
  return async (dispatch) => {
    try {
      const { data } = await request.get(`/sides`);
      dispatch(dataActions.getSides(data.side));
    } catch (error) {
      console.log(error);
    }
  };
};
